:root {
  --primary-color: #61CE70;
  --secondary-color: #6C1C80;
  --auxiliary-color: #61CE70;

  --header-link-color: #61CE70;

  --recent-icons-background: #D1FBE1;
  --recent-icons-color: #000;
  --recent-icons-border: #D1FBE1;

  --pix-keys-manage-color: #61CE70;
  --pix-keys-manage-button-background: transparent;
  --pix-keys-manage-button-color: #6C1C80;
  --pix-keys-manage-button-border: #6C1C80;

  --pix-modal-cancel-button-background: transparent;
  --pix-modal-cancel-button-color: #61CE70;
  --pix-modal-cancel-button-border: #61CE70;

  --pix-modal-confirm-button-background: #61CE70;
  --pix-modal-confirm-button-color: #FFFFFF;
  --pix-modal-confirm-button-border: #61CE70;

  --pix-transfer-confirm-button-background: #61CE70;
  --pix-transfer-confirm-button-color: #FFFFFF;
  --pix-transfer-confirm-button-border: #61CE70;

  --pix-transfer-contact-button-background: transparent;
  --pix-transfer-contact-button-color: #6C1C80;
  --pix-transfer-contact-button-border: #6C1C80;

  --pix-transfer-modal-cancel-button-background: transparent;
  --pix-transfer-modal-cancel-button-color: #61CE70;
  --pix-transfer-modal-cancel-button-border: #61CE70;

  --pix-transfer-modal-confirm-button-background: #61CE70;
  --pix-transfer-modal-confirm-button-color: #FFFFFF;
  --pix-transfer-modal-confirm-button-border: #61CE70;

  --pix-transfer-modal-input-background: #ffffff;
  --pix-transfer-modal-input-color: #000;
  --pix-transfer-modal-input-border: #000;

  --pix-limits-cancel-button-background: transparent;
  --pix-limits-cancel-button-color: #6C1C80;
  --pix-limits-cancel-button-border: #6C1C80;

  --pix-limits-confirm-button-background: #61CE70;
  --pix-limits-confirm-button-color: #FFFFFF;
  --pix-limits-confirm-button-border: #61CE70;

  --share-receipt-button-background: transparent;
  --share-receipt-button-color: #6C1C80;
  --share-receipt-button-border: #6C1C80;

  --ted-page-confirm-button-background: #61CE70;
  --ted-page-confirm-button-color: #FFFFFF;
  --ted-page-confirm-button-border: #61CE70;

  --ted-transfer-cancel-button-background: transparent;
  --ted-transfer-cancel-button-color: #6C1C80;
  --ted-transfer-cancel-button-border: #6C1C80;

  --ted-transfer-confirm-button-background: #61CE70;
  --ted-transfer-confirm-button-color: #FFFFFF;
  --ted-transfer-confirm-button-border: #61CE70;

  --pay-ticket-page-button-background: #61CE70;
  --pay-ticket-page-button-color: #000;
  --pay-ticket-page-button-border: #000;

  --generate-ticket-page-button-background: transparent;
  --generate-ticket-page-button-color: #000;
  --generate-ticket-page-button-border: #000;

  --cancel-ticket-button-background: transparent;
  --cancel-ticket-button-color: #6C1C80;
  --cancel-ticket-button-border: #6C1C80;

  --confirm-ticket-button-background: #61CE70;
  --confirm-ticket-button-color: #FFFFFF;
  --confirm-ticket-button-border: #61CE70;

  --generate-ticket-select-background: #FFFFFF;
  --generate-ticket-select-color: #000;
  --generate-ticket-select-border: #000;

  --pay-ticket-cancel-button-background: transparent;
  --pay-ticket-cancel-button-color: #6C1C80;
  --pay-ticket-cancel-button-border: #6C1C80;

  --pay-ticket-confirm-button-background: #61CE70;
  --pay-ticket-confirm-button-color: #FFFFFF;
  --pay-ticket-confirm-button-border: #61CE70;

  --delete-photo-button-background: transparent;
  --delete-photo-button-color: #61CE70;
  --delete-photo-button-border: none;

  --update-info-settings-button-background: transparent;
  --update-info-settings-button-color: #6C1C80;
  --update-info-settings-button-border: #6C1C80;

  --change-password-button-background: #61CE70;
  --change-password-button-color: #FFFFFF;
  --change-password-button-border: #61CE70;

  --change-password-modal-button-background: #61CE70;
  --change-password-modal-button-color: #FFFFFF;
  --change-password-modal-button-border: #61CE70;

  --checkbox-border: #000;
  --checkbox-color: #ffffff;

  --bg-color: #FFFFFF;
  --bg-secondary-color: #210328;
  --bg-auxiliary-color: #dedfe0;

  --bg-circle-color: #FFFFFF;
  --bg-circle-hover: #61CE70;
  --bg-circle-text-color: #000;
  --bg-circle-text-color-hover: #000;

  --bg-modal-color: #FFFFFF;
  --bg-input-color: #FFFFFF;

  --dark-color: #3F3F3F;

  --text-primary: #121212;
  --text-secondary: #121212;
  --text-gray: #4F4F4F;

  --input-color: #121212;
  --menu-active-icon-color: #61CE70;
  --info-color: #D1FBE1;

  --disabled-color: #6c757d;
  --card-color: #edeef0;

  --modal-border-color: #ADB5BD;
  --border-color: #FFFFFF;

  --card-button-navigation-color:#61CE70;
  --card-button-color:#61CE70;
  --card-button-border-color:#61CE70;
  --card-bg-icon-color:#61CE70;
  --card-text-primary-color:#61CE70;
  --card-bg-primary-color:#61CE70;

}
